.calendar-container {
  padding: 20px;
  margin-right: 30px;
  color: #bfbfbf;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day-header {
  text-align: center;
  font-weight: bold;
}

.calendar-day {
  background-color: #333;
  padding-top: 25px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  height: 60px;
}

.calendar-day:hover {
  background-color: #444;
}

.day-number {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #fff;
}

.events-mini {
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
}

.event-mini {
  background-color: #555;
  color: #fff;
  margin-bottom: 2px;
  padding: 2px;
  border-radius: 3px;
  max-height: 15px;
}

.events-container {
  margin-top: 20px;
}

.event {
  background-color: #444;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}
  
.past-event {
  background-color: rgba(255, 0, 0, 0.2);
}

.future-event {
  background-color: rgba(0, 255, 0, 0.2);
}

/* Rotate Calendar for Mobile */
@media (max-width: 768px) {
    .calendar-container {
        margin-right: 10px;
    }
  }
